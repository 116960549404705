import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/style.scss';
import Redirect from "react-router-dom/es/Redirect";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
const AdminLogin = React.lazy(() => import('./containers/admin/Login/AdminLogin'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <AdminLogin {...props}/>}/>
                        <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                        <Route path="/" name="Admin" render={props =>
                            (localStorage.getItem("user") != null) ?
                                <TheLayout {...props}/> :
                                <Redirect from="/dashboard" to="/login"/>
                        }/>
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;
